.mainwrapper {
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.completed-cards {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;

  text-align: center;
}

.button-two {
  padding: 0.7rem;
  outline: none;
  border: none;
  border-radius: 3px;
  background: white;
  color: black;
  font-weight: bold;
  cursor: pointer;
  transition: 0.4s ease;
}

/* a:hover {
  background-color: blue;
  color: white;
  text-decoration: none;
} */

.body-container-two {
  display: flex;

  /* align-items: flex-start; */
  justify-content: center;
  background: transparent;
}

.wrapper-two {
  display: flex;

  width: 70vw;
  justify-content: center;
  padding: 2em;
}

.card-one {
  justify-content: space-evenly;
  width: 40vw;
  height: 75vh;
  border-radius: 15px 0px 0px 15px;
  padding: 1.5rem;
  background: white;
  /* margin: 0.5em; */
  border: 1px solid gray;
  /* position: relative; */

  /* align-items: flex-start; */
  transition: 0.4s ease-out;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.5);
}

.card-two {
  justify-content: space-evenly;
  width: 40vw;
  height: 75vh;
  /* border-radius: 15px 0px 0px 15px; */
  padding: 1.5rem;
  background: white;
  /* margin: 0.5em; */
  border: 1px solid gray;
  /* position: relative; */

  /* align-items: flex-end; */
  transition: 0.4s ease-out;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.5);
}

.card-three {
  justify-content: space-evenly;
  width: 40vw;
  height: 75vh;
  border-radius: 0px 15px 15px 0px;
  padding: 1.5rem;
  background: white;
  /* margin: 0.5em; */
  border: 1px solid gray;

  /* position: relative; */

  /* align-items: flex-end; */
  transition: 0.4s ease-out;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.5);
}

.info-two h2 {
  color: #22b77e;
}

.info-two i {
  font-size: 2rem;
  color: darkgray;
  padding: 10px;
}
/* .card-two:hover {
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
}
.card-two:hover:before {
  opacity: 1;
}
.card-two:hover .info-two {
  opacity: 1;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}
.card-two:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
  transition: 0.5s;
  opacity: 0;
}
.card-two img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 15px;
}
.card-two .info-two {
  position: relative;
  z-index: 3;
  color: white;
  opacity: 0;
  -webkit-transform: translateY(30px);
  transform: translateY(30px);
  transition: 0.5s; 
}*/
.card-two .info-two h1 {
  margin: 0px;
}

.card-two .info-two button {
  padding: 0.6rem;
  outline: none;
  border: none;
  border-radius: 3px;
  background: white;
  color: black;
  font-weight: bold;
  cursor: pointer;
  transition: 0.4s ease;
}
.card-two .info-two button:hover {
  background: dodgerblue;
  color: white;
}

.info-two p {
  letter-spacing: 1px;
  font-size: 1.2rem;
  margin-top: 5px;
}

hr {
  background-color: lightblue;
}
/******************** sectionmapcontact css **************************/
.body-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  float: left;
  /* margin-bottom: 35vh; */
  position: relative;
  /* overflow: hidden; */
}

.contact {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  /* height: 300px; */
  position: relative;
  /* float: left; */
  /* z-index: 1; */
  /* margin-top: -35vh; */
  width: 45vw;
  /* align-items: center; */
  /* background: rgb(76, 155, 175, 0.8); */
}

.map {
  float: left;
  margin-bottom: -75vh;
  height: 75vh;
  position: relative;
  /* z-index: 99; */
  /* flex-direction: column; */
  /* flex: 1; */
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  /* opacity: 0.5; */
}
/********** end sectionmapcontact css ************************/

@media only screen and (min-width: 700px) {
  .card-one {
    justify-content: space-evenly;
    width: 40vw;
    height: 75vh;
    border-radius: 15px 0px 0px 15px;
    padding: 1.5rem;
    background: white;
    margin: 0.5em;
    border: 1px solid gray;
    /* position: relative; */

    /* align-items: flex-start; */
    transition: 0.4s ease-out;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.5);
  }

  .card-two {
    justify-content: space-evenly;
    width: 40vw;
    height: 75vh;
    /* border-radius: 15px 0px 0px 15px; */
    padding: 1.5rem;
    background: white;
    margin: 0.5em;
    border: 1px solid gray;
    /* position: relative; */

    /* align-items: flex-end; */
    transition: 0.4s ease-out;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.5);
  }

  .card-three {
    justify-content: space-evenly;
    width: 40vw;
    height: 75vh;
    border-radius: 0px 15px 15px 0px;
    /* padding: 1.5rem; */
    background: white;
    margin: 0.5em;
    border: 1px solid gray;

    /* position: relative; */

    /* align-items: flex-end; */
    transition: 0.4s ease-out;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.5);
  }
}

@media only screen and (max-width: 500px) {
  .body-container-two {
    width: 100vw;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    background: transparent;
  }
  .wrapper-two {
    display: flex;
    flex-direction: column;
    align-content: center;
    width: 100vw;
    justify-content: center;
    align-items: center;
  }

  .card-one {
    justify-content: center;
    width: 75vw;
    height: 75vh;
    border-radius: 15px 15px 15px 15px;
    padding: 1.5rem;
    background: white;
    margin: 0.5em;
    border: 1px solid gray;

    /* align-items: flex-start; */
    transition: 0.4s ease-out;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.5);
  }

  .card-two {
    justify-content: center;
    width: 75vw;
    height: 75vh;
    border-radius: 15px 15px 15px 15px;
    padding: 1.5rem;
    background: white;
    margin: 0.5em;
    border: 1px solid gray;
    /* position: relative; */

    /* align-items: flex-end; */
    transition: 0.4s ease-out;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.5);
  }

  .card-three {
    justify-content: center;
    width: 75vw;
    height: 75vh;
    border-radius: 15px 15px 15px 15px;
    /* padding: 1.5rem; */
    background: white;
    margin: 0.5em;
    border: 1px solid gray;

    /* position: relative; */

    /* align-items: flex-end; */
    transition: 0.4s ease-out;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.5);
  }
  /******* map conact section media query 500px ********/

  .body-container {
    position: relative;
    float: none;
    /* display: flex; */
    height: 175vh;
    width: 100vw;
    /* flex-direction: column; */
    /* justify-content: center; */
  }

  .map {
    position: absolute;
    top: 0px;
    float: none;
    height: 75vh;
    width: 100vw;
    /* align-items: center; */
  }
  .contact {
    float: none;
    position: absolute;
    bottom: 0px;
    height: 100vh;
    /* align-items: center; */
    width: 100vw;
    background: hsl(0, 3%, 79%);
    /* margin-top: -75; */
  }
}

@media only screen and (max-width: 312px) {
  .body-container-two {
    width: 100vw;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    background: transparent;
  }
  .wrapper-two {
    display: flex;
    flex-direction: column;
    align-content: center;
    width: 100vw;
    justify-content: center;
    align-items: center;
  }

  .card-one {
    justify-content: center;
    width: 75vw;
    height: 75vh;
    border-radius: 15px 15px 15px 15px;
    padding: 1.5rem;
    background: white;
    margin: 0.5em;
    border: 1px solid gray;

    /* align-items: flex-start; */
    transition: 0.4s ease-out;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.5);
  }

  .card-two {
    justify-content: center;
    width: 75vw;
    height: 75vh;
    border-radius: 15px 15px 15px 15px;
    padding: 1.5rem;
    background: white;
    margin: 0.5em;
    border: 1px solid gray;
    /* position: relative; */

    /* align-items: flex-end; */
    transition: 0.4s ease-out;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.5);
  }

  .card-three {
    justify-content: center;
    width: 75vw;
    height: 75vh;
    border-radius: 15px 15px 15px 15px;
    /* padding: 1.5rem; */
    background: white;
    margin: 0.5em;
    border: 1px solid gray;

    /* position: relative; */

    /* align-items: flex-end; */
    transition: 0.4s ease-out;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.5);
  }
  /******* map conact section media query 500px ********/

  .body-container {
    position: relative;
    float: none;
    /* display: flex; */
    height: 175vh;
    width: 100vw;
    /* flex-direction: column; */
    /* justify-content: center; */
  }

  .map {
    position: absolute;
    top: 0px;
    float: none;
    height: 75vh;
    width: 100vw;
    /* align-items: center; */
  }
  .contact {
    float: none;
    position: absolute;
    bottom: 0px;
    height: 100vh;
    /* align-items: center; */
    width: 100vw;
    background: hsl(0, 3%, 79%);
    /* margin-top: -75; */
  }
}
