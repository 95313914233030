.contact-wrapper {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  border: medium dashed lightgray;
  height: 100vh;
  background: white;
  border-radius: 0px 20px 20px 0px;
}

.contact-wrapper h3 {
  text-align: center;
  color: #22b77e;
}

.contact-form {
  max-width: 75vw;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 16px;
  font-size: 2rem;
}

.contact-form > :not(:last-child) {
  margin-bottom: 16px;
}

.contact-form .form-field {
  display: flex;
  flex-direction: column;
  line-height: 5rem;
}

.contact-form .label-content {
  margin-bottom: 4px;
  font-size: 1.2rem;
}

.contact-form input {
  display: flex;
  flex: 1;
  width: 30vw;
  max-width: 35vw;
  line-height: normal;
  height: 2.5em;
  outline: none;
  border: none;
  background: none;
}

.contact-form textarea {
  overflow: auto;
}

.contact-form label {
  text-align: left;
}

button {
  padding: 0.7rem;
  outline: none;
  border: none;
  border-radius: 5px;

  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: 0.8s ease;
  letter-spacing: 2px;

  background: #1e9974; /* Old browsers */
}

button:hover {
  letter-spacing: 4px;
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  box-shadow: 5px 40px -10px rgba(0, 0, 0, 0.57);
  transition: all 0.5s ease 0s;

  color: white;
  text-decoration: none;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#1e9974+0,22b77e+44,1fc47f+68,7be5be+100 */
  background: #1e9974; /* Old browsers */
  background: -moz-linear-gradient(
    left,
    #1e9974 0%,
    #22b77e 44%,
    #1fc47f 68%,
    #7be5be 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #1e9974 0%,
    #22b77e 44%,
    #1fc47f 68%,
    #7be5be 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    #1e9974 0%,
    #22b77e 44%,
    #1fc47f 68%,
    #7be5be 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e9974', endColorstr='#7be5be',GradientType=1 ); /* IE6-9 */
}

hr {
  background-color: rgb(59, 51, 51);
}

.label-content input {
  border: none;
  border-bottom: 2px dashed #0e0d0d;
  color: black;
}

.label-content textarea {
  border: none;
  border-bottom: 2px dashed #0e0d0d;
  background: none;
}

/* ******************new css*************** */
