.resources-section {
  display: flex;
  /* height: 100vh; */
  width: 100vw;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100vw;
  /* position: relative; */

  /* margin: 20px; */
}

.icon {
  display: flex;

  justify-content: center;
  align-items: center;
  font-size: 8vw;
  width: 5em;
}

.white {
  color: white;
}

.gray {
  background-color: hsl(0, 3%, 79%);
}
.lt-blue {
  background-color: #a5afb3;
}
