.button {
  padding: 0.7rem;
  outline: none;
  border: none;
  border-radius: 3px;
  background: white;
  color: black;
  font-weight: bold;
  cursor: pointer;
  transition: 0.4s ease;
}

.button a:hover {
  background-color: blue;
  color: white;
  text-decoration: none;
}

.body-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background: transparent;
}

.project-title {
  display: flex;
  width: 100vw;
  align-content: center;
  justify-content: center;
  color: black;
  margin-top: 10px;
}

.project-title h4 {
  color: #22b77e;
}

.wrapper {
  display: flex;
  width: 100vw;
  justify-content: center;
}

.card {
  /* justify-content: space-between; */
  flex-grow: 1;
  max-width: 345px;
  width: 100vw;
  margin: 1.5em;
  /* position: relative; */
  /* height: 45vh; */
  /* border-radius: 15px;
  padding: 1.5rem;
  background: white;
  align-items: center;
  transition: 0.4s ease-out;*/
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
}
/* .card:hover {
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
}
.card:hover:before {
  opacity: 1;
}
.card:hover .info {
  opacity: 1;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}
.card:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.9);
  z-index: 2;
  transition: 0.5s;
  opacity: 0;
} */
.card img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 15px;
}

.info {
  margin: 1em;
}

.card .info {
  position: relative;

  z-index: 3;
  /* color: white; */
  opacity: 0;
  -webkit-transform: translateY(30px);
  transform: translateY(30px);
  transition: 0.5s;
}
.card .info h2 {
  margin: 0px;
}
.card .info p {
  letter-spacing: 1px;
  font-size: 1.5rem;
  margin-top: 8px;
}
.card .info .button {
  padding: 0.6rem;
  outline: none;
  border: none;
  border-radius: 3px;
  /* background: white; */
  color: black;
  font-weight: bold;
  cursor: pointer;
  transition: 0.4s ease;
}
.card .info .button:hover {
  background: dodgerblue;
  /* color: white; */
  text-decoration: none;
}

@media only screen and (max-width: 700px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    width: 100vw;
    /* height: 250vh; */
    align-content: center;
    align-items: center;
    /* justify-content: center; */
  }

  .card {
    justify-content: center;
    align-items: center;
    width: 65vw;
    /* height: 40vh; */
    /* border-radius: 15px; */
    /* padding: 0.5rem; */
    /* background: white; */
    margin: 1em;
    /* position: relative; */
    /* overflow-wrap: break-word; */
    /* align-items: flex-end; */
    transition: 0.4s ease-out;
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
  }
  .card .info p {
    letter-spacing: 1px;
    font-size: 1.2rem;
    margin-top: 3px;
  }
}

@media only screen and (max-width: 500px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    width: 100vw;
    align-content: center;
    align-items: center;
    /* justify-content: center; */
  }

  .card {
    /* justify-content: center; */
    width: 65vw;
    height: 40vh;
    /* border-radius: 15px; */
    padding: 0.5rem;
    /* background: white; */
    margin: 1em;
    /* position: relative; */

    /* align-items: flex-end; */
    transition: 0.4s ease-out;
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
  }
  .card .info p {
    letter-spacing: 1px;
    font-size: 1rem;
    margin-top: 2px;
  }
}

.MuiPaper-elevation1 {
  box-shadow: none;
}
